<script setup lang="ts">
import { Accommodation } from '~/submodules/sharedTypes/common/Accommodation'
import { UserflowId } from '~~/src/constants/UserflowId'
import { EmitsEnum } from '~~/src/constants/emits'
import { TweakModifierType } from '~/submodules/sharedTypes/common/Modifiers'
import { NumericVariationType } from '~/submodules/sharedTypes/common/NumericVariationType'
import { BasePriceVariationModifierSettings } from '@/types/StartingPrices'
import { TranslationKeys } from '~/i18n/TranslationKeys'

const props = defineProps({
	accommodation: {
		type: Object as PropType<Accommodation>,
		required: true,
	},
	roomTypeId: {
		type: Number,
		required: true,
	},
	initialValue: {
		type: Object as PropType<BasePriceVariationModifierSettings>,
		required: false,
	},
})
const { accommodation, roomTypeId, initialValue } = toRefs(props)

const currentValue = ref(
	initialValue?.value || ({ variationType: NumericVariationType.Amount, variationValue: 0 } as TweakModifierType)
)

const emit = defineEmits([EmitsEnum.Change])
const updateValue = (value: any) => {
	currentValue.value = { ...value }
	emit(EmitsEnum.Change, value, roomTypeId.value)
}
</script>
<template>
	<div>
		<div class="bg-dark-blue-50">
			<div
				class="grid grid-cols-2 pr-3 truncate border rounded-md border-dark-blue-200"
				:data-id="UserflowId.CollapsablePriceModifiersSingleModifier"
			>
				<div class="flex items-center gap-2 py-2 pl-3 text-dark-blue-800 bg-main-blue-100 w-[215px]">
					<CommonText :text="TranslationKeys.RULE" />
				</div>
				<ModifiersEditorTweak
					:currency="accommodation.currency"
					:is-compact="true"
					@change="updateValue"
					:current-value="currentValue"
				/>
			</div>
		</div>
	</div>
</template>
