<script setup lang="ts">
import { PropType } from 'vue'
import { TextSizes } from '~~/src/constants/textSizes'
import { DateRange } from '~~/src/submodules/sharedTypes/common/DateRange'
import { utilDate } from '~~/src/utils/utilDate'

const props = defineProps({
	date: { type: Object as PropType<Date | DateRange> },
	backgroundColor: { type: String },
	textColor: { type: String },
})
const { date, backgroundColor, textColor } = toRefs(props)

const readableDate = computed(() => {
	if (date == undefined) {
		return ''
	}

	// @ts-ignore
	const isDate = date.value?.from == undefined && date.value?.to == undefined
	if (isDate) {
		return utilDate.formatDate(date.value as Date)
	}

	// now we are sure the date is a daterange
	return utilDate.formatDateRange(date.value as DateRange)
})
</script>

<template>
	<div
		class="w-full rounded-md p-2"
		:class="`${backgroundColor || 'bg-main-blue-100'} ${textColor || 'text-main-blue-600'}`"
	>
		<p :class="TextSizes.BODY_REGULAR">
			{{ readableDate }}
		</p>
	</div>
</template>
